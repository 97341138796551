import React from "react";
import Layout from "../components/Layout/Layout";
import Container from "common/components/UI/Container";
import { graphql, useStaticQuery } from "gatsby";
import { Box, Center, Heading, Text } from "@chakra-ui/react";
import { get } from "lodash";
import parse from "html-react-parser";
import { useI18next } from "gatsby-plugin-react-i18next";

export default function Privacy() {
  const { language } = useI18next();
  const { allStrapiPrivacy } = useStaticQuery(graphql`
    {
      allStrapiPrivacy {
        nodes {
          locale
          title
          contents {
            data {
              contents
            }
          }
          description
        }
      }
    }
  `);

  const privacyData = allStrapiPrivacy.nodes.filter(
    (node) => node.locale === language
  )[0];

  return (
    <Layout title="Privacy" showItems="/privacy">
      <Container width="1400px">
        <Box minHeight="100vh" position="relative">
          <Box height="180px" />
          <Center flexDir="column">
            <Heading
              as="h2"
              color="#000"
              fontSize="2.5rem"
              fontWeight="bold"
              py="20px"
            >
              {get(privacyData, "title", "")}
            </Heading>
            <Text
              mb="10px"
              fontSize="1.25rem"
              color="gray.500"
              fontWeight="normal"
            >
              {get(privacyData, "description", "")}
            </Text>
          </Center>
          <Box lineHeight="24px" textAlign="justify">
            {parse(get(privacyData, "contents.data.contents", ""))}
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}
